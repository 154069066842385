import { useState, useEffect } from "react";

import axios from 'axios';

import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

import TripDisplay from "pages/InfoPages/Trips/components/TripDisplay";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import routes from "routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import { useNavigate } from 'react-router-dom';

import bgImage from "assets/images/bg3.jpg";

const API_URL = process.env.REACT_APP_API_URL || '';

const dateStringOpts = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const buttonStyles = {
  width: "max-content",
  display: "flex",
  alignItems: "center",

  "& .material-icons-round": {
    fontSize: "1.125rem",
    transform: `translateX(3px)`,
    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
  },

  "&:hover .material-icons-round, &:focus .material-icons-round": {
    transform: `translateX(6px)`,
  },
};

const mockData = [
  {
    title: "Niagara with Artie",
    date: (new Date('2024-08-03T04:00:00')).toDateString('en-CA', dateStringOpts),
    description: "A birthday trip for Mel! We are going to Niagara to do some hiking/walks with the dog, have a picnic, potentially see the falls (Artie-dependent), and do a dog-friendly wine tour.",
    locations: [
      {
        description: "Niagara Falls",
        time: "3:00 PM",
        lat: 43.083046,
        lng: -79.068780
      },
      {
        description: "Winery",
        time: "1:00 PM",
        lat: 43.219720,
        lng: -79.127261
      },
      {
        description: "Balls Falls",
        time: "10:00 AM",
        lat: 43.121689,
        lng: -79.342500
      }
    ]
  },
  {
    title: "Niagara with Artie",
    date: (new Date('2024-08-03T04:00:00')).toDateString('en-CA', dateStringOpts),
    description: "A birthday trip for Mel! We are going to Niagara to do some hiking/walks with the dog, have a picnic, potentially see the falls (Artie-dependent), and do a dog-friendly wine tour.",
    locations: [
      {
        description: "Niagara Falls",
        time: "3:00 PM",
        lat: 43.083046,
        lng: -79.068780
      },
      {
        description: "Winery",
        time: "1:00 PM",
        lat: 43.219720,
        lng: -79.127261
      },
      {
        description: "Balls Falls",
        time: "10:00 AM",
        lat: 43.121689,
        lng: -79.342500
      },
    ]
  },
  {
    title: "Niagara with Artie",
    date: (new Date('2024-08-03T04:00:00')).toDateString('en-CA', dateStringOpts),
    description: "A birthday trip for Mel! We are going to Niagara to do some hiking/walks with the dog, have a picnic, potentially see the falls (Artie-dependent), and do a dog-friendly wine tour.",
    locations: [
      {
        description: "Niagara Falls",
        time: "3:00 PM",
        lat: 43.083046,
        lng: -79.068780
      },
      {
        description: "Winery",
        time: "1:00 PM",
        lat: 43.219720,
        lng: -79.127261
      },
      {
        description: "Balls Falls",
        time: "10:00 AM",
        lat: 43.121689,
        lng: -79.342500
      }
    ]
  },
  {
    title: "Niagara with Artie",
    date: (new Date('2024-08-03T04:00:00')).toDateString('en-CA', dateStringOpts),
    description: "A birthday trip for Mel! We are going to Niagara to do some hiking/walks with the dog, have a picnic, potentially see the falls (Artie-dependent), and do a dog-friendly wine tour.",
    locations: [
      {
        description: "Niagara Falls",
        time: "3:00 PM",
        lat: 43.083046,
        lng: -79.068780
      },
      {
        description: "Winery",
        time: "1:00 PM",
        lat: 43.219720,
        lng: -79.127261
      },
      {
        description: "Balls Falls",
        time: "10:00 AM",
        lat: 43.121689,
        lng: -79.342500
      }
    ]
  },
];

function Trips() {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const handleAddTrip = () => {
    navigate('/trips/create');
  };

  useEffect(() => {
    axios.get(`${API_URL}/api/trips`, { withCredentials: true }).then((res) => {
      const resData = res.data;
      console.log(resData);
      setData(resData);
    });
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Our Future Trips{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              View and plan our upcoming trips.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        width="100%"
        pr={"7%"}
        mt={"-6rem"}
        mb={"6rem"}
        sx={{
          placeItems: "end",
          display: "grid"
        }}
      >
        <MKButton color={"white"} onClick={handleAddTrip} ml={"auto"} sx={buttonStyles}>
          Add Trip
        </MKButton>
      </MKBox>
      <MKBox
        width="100%"
      >
        {data.length == 0 && (
          <MKTypography
            variant="body1"
            color="black"
            textAlign="center"
            px={{ xs: 12, lg: 12 }}
            mt={1}
          >
            No Future Trips Found
          </MKTypography>
        )}
        <Container>
          <Grid container spacing={2}>
            {data.length > 0 && (
              data.map((cur, i) =>
                <Grid item xs={12} lg={6} mt={-8} mb={8}>
                  <TripDisplay
                    id={cur._id}
                    title={cur.title}
                    date={cur.date}
                    description={cur.description}
                    locations={cur.locations}
                  />
                </Grid>
              )
            )}            
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Trips;
