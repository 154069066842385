/* eslint-disable prettier/prettier */
// Navbar
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-about-us.jpg";

function Dashboard() {
  const collage = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                  },
              })}
            >
              Your Interactive Memory Book{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Our go-to place to plan, track, and see everything we want to do in the future.
              From trips to photos to food, let us plan and document our life together.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" my={0} py={6}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FilledInfoCard
                  color="info"
                  icon="flag"
                  title="Our Adventures"
                  description="A list of our previous trips together."
                  action={{
                    type: "internal",
                    route: "/adventures",
                    label: "Adventure Time"
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FilledInfoCard
                  variant="gradient"
                  color="info"
                  icon="room"
                  title="Trip Ideas"
                  description="A list of our future trips together."
                  action={{
                    type: "internal",
                    route: "/trips",
                    label: "Future Trips"
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FilledInfoCard
                  color="info"
                  icon="room"
                  title="Books"
                  description="The books we've read and want to read."
                  action={{
                    type: "internal",
                    route: "/books",
                    label: "The Book Nook"
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FilledInfoCard
                  color="info"
                  icon="room"
                  title="Recipes"
                  description="Recipes we've enjoyed and ones we want to try."
                  action={{
                    type: "internal",
                    route: "/recipes",
                    label: "Back to the Kitchen"
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FilledInfoCard
                  color="info"
                  icon="room"
                  title="Dates"
                  description="Memories of our smaller outings and fun nights for the future."
                  action={{
                    type: "internal",
                    route: "/dates",
                    label: "Date Night"
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FilledInfoCard
                  color="info"
                  icon="room"
                  title="TV/Movies"
                  description="A list of the videos we've liked and what we want to watch together."
                  action={{
                    type: "internal",
                    route: "/media",
                    label: "Movie Time"
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </MKBox>

        <MKBox component="section" my={3} py={3}>
          <Container>
            <Grid container item xs={12} lg={6} flexDirection="column" alignItems="center"
                sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}>
              <MKTypography variant="h2" fontWeight="bold">
                Our Photos
              </MKTypography>
              <MKTypography variant="body1" color="text">
                A random collage of our photos together. See below for the complete gallery.
              </MKTypography>
            </Grid>
          </Container>
          <Container>
            <Grid container spacing={3}>
              {collage.map((x, i) => 
                <Grid item xs={12} lg={4} key={i}>
                  <img
                    width="100%"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png"
                    alt="Test">
                  </img>
                </Grid>
              )}
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default Dashboard;