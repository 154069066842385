import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function LocationInfo({ description, time, header }) {
	if (!header) {
		header = false;
	}

	const sxOpts = header ? { textDecoration: 'underline' } : {};

	return (
		<>
			<MKBox
				display={{ xs: "block", md: "flex" }}
				bgColor={'white'}
				borderRadius="xl"
				mt={1}
				px={3}
				py={2}
				width={"100%"}
				sx={{
					boxShadow: ({ boxShadows: { xl } }) => xl,
				}}
			>
				<Grid item xs={12} lg={8}>
					<MKTypography
						display={"block"}
						variant={"body1"}
						color={"black"}
						fontWeight={header ? "bold" : ""}
						sx={sxOpts}>
						{description}
					</MKTypography>
				</Grid>
				<Grid item xs={12} lg={4}>
					<MKTypography
						display={"block"}
						variant={"body1"}
						color={"black"}
						fontWeight={header ? "bold" : ""}
						sx={sxOpts}>
						{time}
					</MKTypography>
				</Grid>
			</MKBox>
		</>
	);
}

export default LocationInfo;