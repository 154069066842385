import { useState, useEffect } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from 'components/MKInput';
import MKButton from 'components/MKButton';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function LocationEdit({ description, time, header, cb_update, cb_delete }) {
	const _12To24Hour = (time) => {
		let vals = time.split(':').map((val) => parseInt(val));
		if (time.includes('AM')) {
			time = time.replace(' AM', '');
			time = `${String((vals[0] % 12)).padStart(2, '0')}:${String(vals[1]).padStart(2, '0')}`;
		} else if (time.includes(' PM')) {
			time = time.replace(' PM', '');
			time = `${String((vals[0] % 12) + 12).padStart(2, '0')}:${String(vals[1]).padStart(2, '0')}`;
		}
		return time;
	};

	const _24To12Hour = (timeText) => {
		let vals = timeText.split(':').map((val) => parseInt(val));
		let time;
		let hour = vals[0] % 12;
		if (hour == 0) {
			hour = 12;
		}
		if (vals[0] >= 12) {
			time = `${String(hour).padStart(2, '0')}:${String(vals[1]).padStart(2, '0')} PM`;
		} else {
			time = `${String(hour).padStart(2, '0')}:${String(vals[1]).padStart(2, '0')} AM`;
		}
		return time;
	};

	//const [descText, setDescText] = useState(description);
	//const [timeText, setTimeText] = useState(_12To24Hour(time));

	if (!header) {
		header = false;
	}

	const handleDescriptionChange = (event) => {
		//setDescText(event.target.value);
		cb_update('description', event.target.value);
	};

	const handleTimeChange = (event) => {
		//setTimeText(event.target.value);
		cb_update('time', _24To12Hour(event.target.value));
	};

	const sxOpts = header ? { textDecoration: 'underline' } : {};

	return (
		<>
			<MKBox
				display={{ xs: "block", md: "flex" }}
				bgColor={'white'}
				borderRadius="xl"
				mt={1}
				px={3}
				py={2}
				width={"100%"}
				sx={{
					boxShadow: ({ boxShadows: { xl } }) => xl,
				}}
			>
				<Grid item xs={12} lg={9}>
					<MKInput label="Location" onChange={handleDescriptionChange} value={description} style={{width: "100%", paddingRight: "16px"}} />
				</Grid>
				<Grid item xs={12} lg={2}>
					<MKInput type="time" label="Time" onChange={handleTimeChange} value={_12To24Hour(time)} style={{ width: "100%" }} />
				</Grid>
				<Grid item xs={12} lg={1}>
					<MKButton iconOnly={"true"} onClick={cb_delete} style={{width: "100%"}}>
						<DeleteForeverIcon />
					</MKButton>
				</Grid>
			</MKBox>
		</>
	);
}

export default LocationEdit;