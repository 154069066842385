/* eslint-disable prettier/prettier */
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import routes from "routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import bgImage from "assets/images/bg3.jpg";

function Books() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}>
              Coming Soon!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Books;
