import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import MKBox from "components/MKBox";

import routes from "routes";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from 'components/MKTypography';
import MKInput from 'components/MKInput';
import MKButton from 'components/MKButton';

import LocationEdit from './components/LocationEdit';

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

const dateStringOpts = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const mockData =
{
	title: "",
	date: (new Date()).toDateString('en-CA', dateStringOpts),
	description: "",
	locations: []
};

//{
//	description: "Niagara Falls",
//		time: "3:00 PM",
//			lat: 43.083046,
//				lng: -79.068780
//}


function CreateTrip({ match }) {
	const [searchLocation, setSearchLocation] = useState('');
	const [mapLocation, setMapLocation] = useState(null);
	const [mapLocationMarker, setMapLocationMarker] = useState(null);
	const { id } = useParams();
	const navigate = useNavigate();

	const mapRef = useRef(null);
	const markersRef = useRef(null);

	const data = mockData;

	const [title, setTitle] = useState(data.title);
	const [date, setDate] = useState(data.date);
	const [description, setDescription] = useState(data.description);
	const [locations, setLocations] = useState([]);

	const handleTitleChange = (event) => {
		setTitle(event.target.value);
	};

	const handleDateChange = (event) => {
		setDate(event.target.value);
	};

	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};

	const handleLocationSearchChange = (event) => {
		setSearchLocation(event.target.value);
	};

	const handleSearchLocation = () => {
		let query = searchLocation;
		fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${query}`)
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
				const cur = data[0];
				setMapLocation(cur);
			});
	};

	const handleCreate = async () => {
		const data = {
			title: title,
			date: date,
			description: description,
			locations: locations
		};
		
		const response = await axios.post(`${API_URL}/api/trips`, data, { withCredentials: true });
		if (response.status == 200) {
			navigate('/trips');
		}
	};

	const handleAddLocation = () => {
		if (mapLocation) {
			let newLocations = [...locations];

			let newLocation = {
				description: mapLocation.name.length > 0 ? mapLocation.name : mapLocation.display_name,
				lat: parseFloat(mapLocation.lat),
				lng: parseFloat(mapLocation.lon),
				time: "12:00 AM"
			};

			let locInLocs = false;
			for (let i = 0; i < newLocations.length; i++) {
				let curEq = true;
				for (let key in newLocation) {
					if (newLocation[key] != newLocations[i][key]) {
						curEq = false;
						break;
					}
				}
				if (curEq) {
					locInLocs = true;
					break;
				}
			}

			if (locInLocs == false) {
				newLocations.push(newLocation);
				setLocations(newLocations);
			}
		}
	};

	const handleResetView = () => {
		if (locations.length > 0) {
			const bounds = L.latLngBounds(locations.map((loc) => L.latLng(loc.lat, loc.lng)));

			mapRef.current.fitBounds(bounds, { padding: [50, 50] });
		}
	};

	const handleLocationValueChange = (i, key, value) => {
		let newLocations = [...locations];
		newLocations[i][key] = value;
		setLocations(newLocations);
	};

	const handleDeleteLocation = (i) => {
		let newLocations = [...locations];
		newLocations.splice(i, 1);
		setLocations(newLocations);
	};

	useEffect(() => {
		if (!mapRef.current) {
			mapRef.current = L.map('map');
		}

		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 19
		}).addTo(mapRef.current);

		markersRef.current = L.featureGroup().addTo(mapRef.current);
	}, []);

	useEffect(() => {
		markersRef.current.clearLayers();
		if (locations.length > 0) {
			locations.forEach((loc) => {
				const marker = L.marker([loc.lat, loc.lng]).addTo(markersRef.current);

				marker.bindPopup(loc.description);

				marker.on('mouseover', function (e) {
					this.openPopup();
				});
				marker.on('mouseout', function (e) {
					this.closePopup();
				});
			});

			const bounds = L.latLngBounds(locations.map((loc) => L.latLng(loc.lat, loc.lng)));

			mapRef.current.fitBounds(bounds, { padding: [50, 50] });

			//console.log(markersRef.current.getLayers().length);
		}
	}, [locations]);

	useEffect(() => {
		if (mapLocationMarker) {
			mapRef.current.removeLayer(mapLocationMarker);
		}

		const cur = mapLocation;
		if (cur) {
			const lat = parseFloat(cur.lat);
			const lng = parseFloat(cur.lon);
			const marker = L.marker([lat, lng]).addTo(mapRef.current);

			marker.bindPopup(cur.name);

			marker.on('mouseover', function (e) {
				this.openPopup();
			});
			marker.on('mouseout', function (e) {
				this.closePopup();
			});

			mapRef.current.setView([lat, lng], 13);

			setMapLocationMarker(marker);
		}
	}, [mapLocation]);

	return (
		<>
			<DefaultNavbar
				routes={routes}
				dark
				sticky
			/>
			<MKBox
				minHeight="15vh"
				width="100%"
			>
			</MKBox>
			<MKBox
				width="75%"
				borderRadius="xl"
				mx="auto"
				component="section"
				p={3}
				sx={{
					boxShadow: ({ boxShadows: { xl } }) => xl,
				}}
			>
				<Container>
					<Grid container item xs={12} lg={12} mx={"auto"} style={{ display: "flex", flexDirection: "column" }}>
						<MKButton style={{ width: "20%", marginLeft: "auto"}} onClick={handleCreate} color={"dark"}>
							Create
						</MKButton>
						<MKTypography 
							variant="h3"
							fontWeight="bold"
							mx="auto"
							mb={2}
							mt={-2}
						>
							Create Trip
						</MKTypography>
						<MKInput label="Trip Title" onChange={handleTitleChange} value={title} style={{ marginBottom: 16 }} />
						<MKInput type="date" onChange={handleDateChange} value={new Date(date).toISOString().slice(0, 10)} style={{ marginBottom: 16 }} />
						<MKInput label="Description" multiline rows={5} InputLabelProps={{shrink: true}} onChange={handleDescriptionChange} value={description} style={{ marginBottom: 16 }} />
						<Grid container spacing={2}>
							<Grid item xs={12} lg={8}>
								<MKInput label="Location Search" onChange={handleLocationSearchChange} style={{width: "100%"}}/>
							</Grid>
							<Grid item xs={12} lg={4}>
								<MKButton style={{ width: "100%" }} onClick={handleSearchLocation} color={"white"}>
									Search
								</MKButton>
							</Grid>
							<Grid item xs={12} lg={12}>
								<div id={"map"} style={{ height: "40vh", width: "100%", zIndex: 0}}></div>
							</Grid>
							{mapLocation &&
								<>
									<Grid item xs={12} lg={6}>
										<MKButton onClick={handleAddLocation} style={{width: "90%", margin: "0 auto"}}>
											Add Location to Trip
										</MKButton>
									</Grid>
									<Grid item xs={12} lg={6}>
										<MKButton onClick={handleResetView} style={{ width: "90%", margin: "0 auto" }}>
											Trip View
										</MKButton>
									</Grid>
								</>
							}
							{locations.length > 0 &&
								<Container>
									<Grid container mt={2}>
										{locations.map((loc, i) =>
											<LocationEdit
												description={loc.description}
												time={loc.time}
												cb_update={(key, value) => handleLocationValueChange(i, key, value)}
												cb_delete={() => handleDeleteLocation(i)}
											/>
										)}
									</Grid>
								</Container>
							}
						</Grid>
					</Grid>
				</Container>
			</MKBox>
		</>
	);
}

export default CreateTrip;