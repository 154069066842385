import { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
//import 'leaflet-routing-machine';

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import { Grid } from '@mui/material';
import LocationInfo from 'pages/InfoPages/Trips/components/LocationInfo';
import { Link } from "react-router-dom";

function TripDisplay({ id, title, date, description, locations }) {
  const buttonStyles = {
    width: "max-content",
    display: "flex",
    alignItems: "center",

    "& .material-icons-round": {
      fontSize: "1.125rem",
      transform: `translateX(3px)`,
      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(6px)`,
    },
  };

  const mapRef = useRef(null);

  const div_id = `map-${id}`;

  locations.sort((a, b) => {
    let dateA = new Date('1970/01/01 ' + a.time);
    let dateB = new Date('1970/01/01 ' + b.time);
    return dateA - dateB;
  });

  useEffect(() => {
    //const lats = locations.map(loc => loc.lat);
    //const lngs = locations.map(loc => loc.lng);
    //const centerLat = (Math.max(...lats) + Math.min(...lats)) / 2;
    //const centerLng = (Math.max(...lngs) + Math.min(...lngs)) / 2;

    if (!mapRef.current) {
      mapRef.current = L.map(div_id);
    }

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19
    }).addTo(mapRef.current);

    //let routing = L.Routing.control({
    //  waypoints: locations.map((loc) => L.latLng(loc.lat, loc.lng)),
    //  routeWhileDragging: true
    //}).addTo(map)
    locations.forEach((loc) => {
      const marker = L.marker([loc.lat, loc.lng]).addTo(mapRef.current);

      marker.bindPopup(loc.description);

      //TO ADD REACT COMPONENT INSTEAD OF JUST TEXT
      //// Create a new div element
      //const contentDiv = document.createElement('div');
      //// Render your custom component into the div
      //ReactDOM.render(<YourComponent />, contentDiv);
      //// Set the content of the popup to the div
      //marker.bindPopup(contentDiv);

      //NEED TO UNMOUNT COMPONENT AT END OF USEEFFECT
      //return () => {
      //  locations.forEach(() => {
      //    ReactDOM.unmountComponentAtNode(contentDiv);
      //  });
      //};

      marker.on('mouseover', function (e) {
        this.openPopup();
      });
      marker.on('mouseout', function (e) {
        this.closePopup();
      });
    });

    const bounds = L.latLngBounds(locations.map((loc) => L.latLng(loc.lat, loc.lng)));

    mapRef.current.fitBounds(bounds, { padding: [50, 50] });

    setTimeout(() => {
      mapRef.current.invalidateSize();
    }, 100);
  }, [locations]);

	return (
    <MKBox
      display={"block"}
      bgColor={'white'}
      borderRadius="xl"
      pt={3.5}
      pb={3}
      px={3}
      lineHeight={1}>
      <MKTypography
        component={Link}
        to={`/trips/edit/${id}`}
        variant={"body2"}
        color={"blue"}
        mb={-3}
        ml={"auto"}
        pr={2}
        sx={buttonStyles}
      >
        Edit
      </MKTypography>
      <MKTypography
        display="block"
        variant="h3"
        color={'black'}
        fontWeight="bold"
      >
        {title}
      </MKTypography>
      <MKTypography
        display="block"
        variant="body1"
        color={'black'}
        mb={1}
      >
        {(new Date(date)).toDateString()}
      </MKTypography>
      <MKTypography
        display="block"
        variant="body2"
        color={'black'}
        mb={2}
      >
        {description}
      </MKTypography>
      <div id={div_id} style={{ height: "40vh", width: "100%", zIndex: 0 }}></div>
      <Container>
        <Grid container spacing={2} mt={1}>
          <LocationInfo
            description={"Activity"}
            time={"Time"}
            header={true}
          />
          {locations.map((cur, i) =>
            <LocationInfo
              description={cur.description}
              time={cur.time}
            />
          )}
        </Grid>
      </Container>
		</MKBox>
	);
}

export default TripDisplay;